<template>
    <div class="home-slider">
		<agile :initial-slide="0" :autoplay="true" :infinite="true">
			<div v-for="(slide,i) in slides" :key="i">
				<img class="slide" :src="slide" >
			</div>
		</agile>
	</div>
</template>
<script>
import { VueAgile } from 'vue-agile'
export default {
	data() {
		return {
			slides: [
				"/img/slider-1.jpg",
				"/img/slider-2.jpg",
				"/img/slider-3.jpg",
				"/img/slider-4.jpg",
				"/img/slider-5.jpg",
				"/img/slider-6.jpg",
				"/img/slider-7.jpg",
			]
		};
	},
	components: {
		agile: VueAgile,
	},
};
</script>


